/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    hr: "hr",
    div: "div",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "quantum NOT gate"), " is the analogue of the classical NOT gate used in quantum computing. It turns a state of $|0\\rangle$ into a state of $|1\\rangle$, and vice versa."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "The quantum NOT gate can be represented in two ways in a quantum circuit. One way is as a circle with a cross in it, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 34.177215189873415%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABJ0AAASdAHeZh94AAAAbklEQVQoz6VRQQrAIAzz/y8VDyIeLIra0YIjSB1sKwSDIaGhjg8z57xfBGrWODRZAWg+acidtdU+OWcupTw2OW5Ya2UiUghPKbH3nmOMGox6a83eED/GGNx7VwiXgBCCBkvArn+qLMZlfl3571EurW4q3d2L9pQAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"quantum-not-gate-symbol\"\n        title=\"quantum-not-gate-symbol\"\n        src=\"/static/dd36fa89ba9181f346e744ab9a794c31/f058b/quantum-not-gate-symbol.png\"\n        srcset=\"/static/dd36fa89ba9181f346e744ab9a794c31/c26ae/quantum-not-gate-symbol.png 158w,\n/static/dd36fa89ba9181f346e744ab9a794c31/6bdcf/quantum-not-gate-symbol.png 315w,\n/static/dd36fa89ba9181f346e744ab9a794c31/f058b/quantum-not-gate-symbol.png 630w,\n/static/dd36fa89ba9181f346e744ab9a794c31/74866/quantum-not-gate-symbol.png 668w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Another way — the more common way — is as a square with the letter $X$ in it, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 34.810126582278485%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAABJ0AAASdAHeZh94AAAAdUlEQVQoz6VR2QrAIAzz//9R8Fl888Izw0KHDN3BAqUhBZOgwAa993PPM99WEG8euBrdGYhVqhmtNYQQEGMkvmuyTZhSgrUWzjna3ntIKaGUIj50vuWc9wlZHClKKTS1VjLQWsMYQ3xofOfEnyo/4bHy3085ADQMKnGcBAyOAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"quantum-not-gate-alternate-symbol\"\n        title=\"quantum-not-gate-alternate-symbol\"\n        src=\"/static/b8de467067e3dcdfb96a209f3c600920/f058b/quantum-not-gate-alternate-symbol.png\"\n        srcset=\"/static/b8de467067e3dcdfb96a209f3c600920/c26ae/quantum-not-gate-alternate-symbol.png 158w,\n/static/b8de467067e3dcdfb96a209f3c600920/6bdcf/quantum-not-gate-alternate-symbol.png 315w,\n/static/b8de467067e3dcdfb96a209f3c600920/f058b/quantum-not-gate-alternate-symbol.png 630w,\n/static/b8de467067e3dcdfb96a209f3c600920/487bb/quantum-not-gate-alternate-symbol.png 698w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The quantum NOT gate is one of a set of three gates called the ", React.createElement(_components.strong, null, "Pauli gates"), ". For this reason, the quantum NOT gate is also known as the ", React.createElement(_components.strong, null, "Pauli-X gate"), ". Each of the Pauli gates performs a rotation of the state around a given axis — x, y, or z — on a Bloch sphere. The quantum NOT gate is equivalent to performing a 180-degree rotation of the state around the x-axis."), "\n", React.createElement(_components.p, null, "The quantum NOT gate has a similar action on the input state to a NOT gate in classical logic circuits. In a classical logic circuit, if the input to a NOT gate is 0, the output is 1, and if the input is 1, the output is 0; the NOT gate flips the input. The same is true of the Pauli-X gate — if the input state is $|0\\rangle$, the Pauli-X gate flips this to $|1\\rangle$, and if the input state is $|1\\rangle$, the Pauli-X gate flips this to $|0\\rangle$."), "\n", React.createElement(_components.p, null, "The Pauli-X gate can be represented in matrix form as"), "\n", React.createElement(_components.p, null, "$$\nX \\equiv \\left[\\begin{matrix}0 & 1\\\\ 1 & 0\\end{matrix}\\right].\n$$"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.X.html"
  }, "Pauli-X gate in PennyLane"), " is available as ", React.createElement(_components.code, null, "qml.X"), " or ", React.createElement(_components.code, null, "qml.PauliX"), ". This gate is called ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/qiskit.circuit.library.XGate"
  }, React.createElement(_components.code, null, "XGate"), " in Qiskit"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
